<template>
  <div v-if="isURLLink">

    <p class="text-black dark:text-white text-md sm:text-md mb-3" v-html="$detectHashtagsAndUsernames(splittedText.text)"
      @click="handleProcessedTextClick"></p>

    <a :href="splittedText.URL"
      class="m-0 text-lg leading-tight text-primary-1100 no-underline hover:no-underline hover:text-gray-900 sm:text-xl">{{
        splittedText.URL }}</a>

    <p class="text-black my-2 dark:text-white">{{ title }}</p>
    <p class="text-sm my-2">
      {{ formatDescription }}
    </p>


    <div v-if="image !== null" class="flex flex-col space-y-2">
      <a :href="splittedText.URL" class="w-full">
        <img class="rounded-xl object-contain " :src="image" alt="Preview" style="max-height: 40vh;" />
      </a>

      <div class="flex flex-col p-3 space-y-1">
      </div>
    </div>


  </div>
  <div v-else>
    <div class="px-5" v-if="element.type === 3">
      <p class="text-black dark:text-white text-md sm:text-md" v-html="$detectHashtagsAndUsernames(element.text)"
        @click="handleProcessedTextClick"></p>
    </div>

    <div class="px-5" v-else-if="[1, 2, 5].includes(element.type)">



      <p class="my-0 py-0">
        <span>
          <Username :user="element.user" />
        </span>
        <span class="text-black dark:text-white text-sm sm:text-md" v-html="$detectHashtagsAndUsernames(element.text)"
          @click="handleProcessedTextClick"></span>
      </p>
    </div>
  </div>
</template>

<script>
import Username from '@/components/user/Username.vue'

const urlPattern = new RegExp('https?://[^\s]+');
export default {
  props: {
    element: {
      type: Object,
      required: true
    },
    loggedUserID: {
      type: Number
    },
    sizePx: Number
  },
  data() {
    return {
      title: '',
      description: '',
      image: null
    }
  },
  components: {
    Username,
  },
  computed: {
    isURLLink() {
      const splittedText = this.element.text.split(' ')

      return splittedText.some(text => urlPattern.test(text))
    },
    splittedText() {
      let newText = ''
      let URLFromText = ''
      const splittedText = this.element.text.split(' ')

      splittedText.forEach(text => {
        if (urlPattern.test(text)) {
          URLFromText = text
          return
        }

        newText += text + ' '
      })

      return { text: newText, URL: URLFromText }
    },
    formatDescription() {
      if (!this.description) return
      if (this.description.length > 75) {
        const formattedDescription = this.description.substring(0, 105)
        let splittedDescription = formattedDescription.split(' ')
        splittedDescription.pop()
        return splittedDescription.join(' ') + '...'
      }
    }
  },

  methods: {
    async createLinkPreview() {
      const res = await getMetadata(this.splittedText.URL)
      console.log(res)
    },
    handleProcessedTextClick(e) {
      if (e.target.matches(".post-hashtag")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/hashtag/all",
            module: null,
            noPaginate: true,
            isMaster: true,
            params: {
              //query: e.target.innerHTML.slice(1),
              name: e.target.innerHTML,
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.$router.push("/post_by_hashtag/" + response.data[0].name.slice(1))
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
      if (e.target.matches(".post-username")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/user/all",
            module: null,
            noPaginate: true,
            params: {
              username: e.target.innerHTML.slice(1),
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.openProfile(response.data[0])
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
  },
  async created() {
    if (!this.isURLLink) return

    const { data, code } = await this.$store
      .dispatch("global/getItems", {
        route: "/post/getMeta",
        module: null,
        noPaginate: true,
        params: {
          url: this.splittedText.URL
        }
      })

    const { title,
      description,
      image } = data


    if (title === 'Calendario - Temporada 2023 - LMBP') {
      console.log(data)
    }
    if (title === "Just a moment..." || !title || code !== 200) return

    this.title = title
    this.description = description
    this.image = image




  }
}
</script>
